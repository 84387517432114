
button {
	all: unset;
}

.TooltipContent {
	border-radius: 4px;
	padding: 10px 15px;
  position: relative;
	font-size: 15px;
	line-height: 1;
	color: black;
	background-color: black;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	user-select: none;
	animation-duration: 400ms;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	will-change: transform, opacity;
}
.TooltipContent[data-state="delayed-open"][data-side="top"] {
	animation-name: slideDownAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="right"] {
	animation-name: slideLeftAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="bottom"] {
	animation-name: slideUpAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="left"] {
	animation-name: slideRightAndFade;
}

.ReactTooltipArrow {
  fill: #EFC38E;
  filter: drop-shadow(0 0 3px EFC38E);
  clip-path: inset(0 -10px -10px -10px);
}

.IconButton {
	cursor: pointer;
  display: flex;
  align-items: center;
}

.ReactTooltipContent {
  z-index: 9999999;
  background-color: white;
  padding: 8px;
  border: 1px solid #EFC38E;
  border-radius: 4px;
}

@keyframes slideUpAndFade {
	from {
		opacity: 0;
		transform: translateY(2px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideRightAndFade {
	from {
		opacity: 0;
		transform: translateX(-2px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slideDownAndFade {
	from {
		opacity: 0;
		transform: translateY(-2px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideLeftAndFade {
	from {
		opacity: 0;
		transform: translateX(2px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
